import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          about: {
            title: "About Us",
            heading1: "Warranty",
            text1:
              "100% translation guarantee. The reliability of our service is confirmed by numerous reviews.",
            heading2: "Confidentiality",
            text2:
              "We do not store or share your personal information with third parties.",
            heading3: "Support",
            text3:
              "Polite technical support around the clock ready to help you on any matter.",
          },
          exchange: {
            title: "Exchange Rates",
            subtitle: "Overlap on the best terms",
            buy: "BUY",
            sell: "SELL",
            expand: "MORE",
            collapse: "HIDE",
          },
          converter: {
            title: "Currency Converter",
            subtitle: "Amount",
            placeholder: "Enter amount",
          },
          partners: {
            title: "Partners",
          },
        },
      },
      ru: {
        translation: {
          about: {
            title: "О нас",
            heading1: "Гарантия",
            text1:
              "100% гарантия перевода. Надежность нашего сервиса подтверждается многочисленными отзывами.",
            heading2: "Конфиденциальность",
            text2:
              "Мы не храним и не передаем Ваши персональные данные третьим лицам.",
            heading3: "Поддержка",
            text3:
              "Вежливая техническая поддержка круглосуточно готова помочь Вам по любому вопросу.",
          },
          exchange: {
            title: "Курсы валют",
            subtitle: "Перекрытие по лучшим условиям",
            buy: "КУПИТЬ",
            sell: "ПРОДАТЬ",
            expand: "ПОКАЗАТЬ ВСЕ",
            collapse: "СКРЫТЬ",
          },
          converter: {
            title: "Конвертер валют",
            subtitle: "Сумма",
            placeholder: "Введите сумму",
          },
          partners: {
            title: "Партнеры",
          },
        },
      },
      ge: {
        translation: {
          about: {
            title: "ჩვენ შესახებ",
            heading1: "გარანტია",
            text1:
              "100% თარგმანის გარანტია. ჩვენი სერვისის სარგებლობა დამტკიცებულია მრავალჯერადი მიმოხილვებით.",
            heading2: "კონფიდენციალურობა",
            text2:
              "ჩვენ არ შევინახავთ და არ გავაზიარებთ თქვენს პირად ინფორმაციას მესამე პირებთან.",
            heading3: "მხარდაჭერა",
            text3:
              "მარტივი ტექნიკური მხარდაჭერა მზადაა დაგეხმაროთ ნებისმიერ კითხვაზე.",
          },
          exchange: {
            title: "ვალუტის კურსები",
            subtitle: "გადახდის უმაღლესი კურსი",
            buy: "ყიდვა",
            sell: "გაყიდვა",
            expand: "მეტი",
            collapse: "დამალვა",
          },
          converter: {
            title: "ვალუტის კონვერტორი",
            subtitle: "თანხა",
            placeholder: "შეიყვანეთ თანხა",
          },
          partners: {
            title: "პარტნიორები",
          },
        },
      },
    },
  });

export default i18n;
