import { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Converter.module.css";
import { useTranslation } from "react-i18next";

function SwitchConversion({ onClick }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={styles.image}
    >
      <rect width="40" height="40" rx="20" fill="white" fillOpacity="0.2" />
      <path
        d="M20.0588 17.7167V18.9457C20.0588 19.3361 20.1355 19.6636 20.2867 19.9187C20.4954 20.2708 20.8379 20.4726 21.2268 20.4726C21.6078 20.4726 21.9864 20.2838 22.352 19.912L26.3428 15.8527C27.2191 14.9614 27.2191 13.5112 26.3428 12.6199L22.352 8.56061C21.9864 8.18855 21.6078 8 21.2268 8C20.6454 8 20.0588 8.47221 20.0588 9.52686V10.643C14.3929 10.9834 9.70238 15.4062 9.00528 21.1968C8.96622 21.5201 9.14701 21.8297 9.44495 21.9496C9.5293 21.9836 9.61712 22 9.70385 22C9.9235 22 10.1366 21.8954 10.2722 21.7068C12.1421 19.1082 15.1443 17.5567 18.303 17.5567C18.8883 17.5567 19.4769 17.6104 20.0588 17.7167Z"
        fill="white"
      />
      <path
        d="M29.5796 18.0504C29.2982 17.9303 28.9772 18.0302 28.7983 18.2934C27.0325 20.8919 24.1971 22.4433 21.2138 22.4433C20.6611 22.4433 20.1052 22.3896 19.5556 22.2833V21.0543C19.5556 19.9997 19.0015 19.5274 18.4525 19.5274C18.0927 19.5274 17.7351 19.7162 17.3896 20.0882L13.6205 24.1473C12.7931 25.0386 12.7931 26.4888 13.6207 27.3803L17.3896 31.4394C17.7351 31.8115 18.0927 32 18.4525 32C19.0015 32 19.5556 31.5278 19.5556 30.4731V29.357C24.9069 29.0166 29.3366 24.5938 29.995 18.8035C30.0319 18.4799 29.8612 18.1703 29.5796 18.0504Z"
        fill="white"
      />
    </svg>
  );
}

function CurrencyConverter() {
  const [exchangeRates, setExchangeRates] = useState([]);
  const [amount1, setAmount1] = useState(1);
  const [amount2, setAmount2] = useState(0);
  const [currency1, setCurrency1] = useState("USD");
  const [currency2, setCurrency2] = useState("RUB");
  const [isReversed, setIsReversed] = useState(false);
  const { t } = useTranslation();
  const server = "back.grandmonetageorgia.com";

  const uniqueCurrencies = [
    "USD",
    "EUR",
    "GEL",
    "RUB",
    "GBP",
    "TRY",
    "AMD",
    "AZN",
    "KZT",
    "UAH",
  ];

  useEffect(() => {
    axios
      .get(`https://${server}/converter/rates`)
      .then((response) => {
        const uniqueCurrencies = [
          "USD",
          "EUR",
          "GEL",
          "RUB",
          "GBP",
          "TRY",
          "AMD",
          "AZN",
          "KZT",
          "UAH",
        ];
        setExchangeRates(
          response.data.filter(
            (rate) =>
              uniqueCurrencies.includes(rate.name.split("/")[0]) &&
              uniqueCurrencies.includes(rate.name.split("/")[1])
          )
        );
      })
      .catch((error) => {
        console.error("Error fetching exchange rates:", error);
      });
  }, []);

  useEffect(() => {
    const selectedRate = exchangeRates.find(
      (rate) =>
        rate.name === `${currency1}/${currency2}` ||
        rate.name === `${currency2}/${currency1}`
    );
    if (selectedRate) {
      const convertedAmount =
        amount1 * (isReversed ? 1 / selectedRate.rate : selectedRate.rate);
      setAmount2(convertedAmount.toFixed(2));
    }
  }, [amount1, currency1, currency2, exchangeRates, isReversed]);

  const handleAmount1Change = (event) => {
    const value = parseFloat(event.target.value) || 0;
    setAmount1(value);
  };

  const handleAmount2Change = (event) => {
    const value = parseFloat(event.target.value) || 0;
    setAmount2(value);
  };

  const handleCurrency1Change = (event) => {
    const newCurrency = event.target.value;
    setCurrency1(newCurrency);
  };

  const handleCurrency2Change = (event) => {
    const newCurrency = event.target.value;
    // Check if the selected currency is the same as currency1
    if (newCurrency !== currency1) {
      setCurrency2(newCurrency);
    } else {
      // If the selected currency is the same as currency1, handle accordingly (e.g., show an error message)
      // You can add your logic here to handle the case where the same currency is selected in both dropdowns
      console.log("You cannot select the same currency in both dropdowns.");
      // You might want to display an error message to the user or handle this case in a different way
    }
  };

  const toggleConversionDirection = () => {
    setIsReversed((prevIsReversed) => !prevIsReversed);
    setCurrency1(currency2);
    setCurrency2(currency1);
  };

  const hideSpinButtonsStyle = {
    WebkitAppearance: "none" /* Chrome, Safari, Edge */,
    MozAppearance: "textfield" /* Firefox */,
    appearance: "textfield" /* W3C standard */,
  };

  return (
    <div className={styles.converter}>
      <h2>{t("converter.title")}</h2>
      <div>
        <p>{t("converter.subtitle")}</p>
        <div className={styles.converter__forms}>
          <form>
            <div className={styles.converter__forms_single}>
              <label>
                <input
                  type="text"
                  value={amount1}
                  onChange={handleAmount1Change}
                  placeholder={t("converter.placeholder")}
                  style={hideSpinButtonsStyle}
                />
              </label>
              <label>
                <select value={currency1} onChange={handleCurrency1Change}>
                  {uniqueCurrencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <SwitchConversion onClick={toggleConversionDirection} />
            <div className={styles.converter__forms_single}>
              <label>
                <input
                  type="text"
                  value={amount2}
                  disabled
                  onChange={handleAmount2Change}
                  style={hideSpinButtonsStyle}
                  placeholder={
                    isReversed ? "Enter converted amount" : "Converted amount"
                  }
                />
              </label>
              <label>
                <select value={currency2} onChange={handleCurrency2Change}>
                  {uniqueCurrencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CurrencyConverter;
