import React, { useState } from "react";
import style from "./Header.module.css";
import { useTranslation } from "react-i18next";

function Header() {
  const [selectedLanguage, setSelectedLanguage] = useState("ge");
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  return (
    <header className={style.header}>
      <a href="/">
        <img src="/gmg_logo.svg" alt="logo" />
      </a>
      <div className={style.header__languages}>
        <ul>
          <li
            className={selectedLanguage === "ge" ? style.selectedLanguage : ""}
          >
            <a href="#" onClick={() => changeLanguage("ge")}>
              GE
            </a>
          </li>
          <li
            className={selectedLanguage === "en" ? style.selectedLanguage : ""}
          >
            <a href="#" onClick={() => changeLanguage("en")}>
              EN
            </a>
          </li>
          <li
            className={selectedLanguage === "ru" ? style.selectedLanguage : ""}
          >
            <a href="#" onClick={() => changeLanguage("ru")}>
              RU
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
