import styles from "./Main.module.css";
import Rates from "../Rates/Rates";
import Converter from "../Converter/Converter";
import { useTranslation } from "react-i18next";

function Main() {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.ellipses}>
        <h1>GRAND MONETA GEORGIA</h1>
      </div>
      <div className={styles.main__container_top}>
        <Rates />
      </div>
      <div className={styles.main__container_bottom}>
        <Converter />
        <h2>{t("about.title")}</h2>
        <div className={styles.main__about__cards}>
          <div className={styles.main__about__card}>
            <img src="/warranty.svg" alt="logo" />
            <h3>{t("about.heading1")}</h3>
            <p>{t("about.text1")}</p>
          </div>
          <div className={styles.main__about__card}>
            <img src="/confidentiality.svg" alt="logo" />
            <h3>{t("about.heading2")}</h3>
            <p>{t("about.text2")}</p>
          </div>
          <div className={styles.main__about__card}>
            <img src="/support.svg" alt="logo" />
            <h3>{t("about.heading3")}</h3>
            <p>{t("about.text3")}</p>
          </div>
        </div>
        <h2>{t("partners.title")}</h2>
        <div className={styles.main__partners}>
          <a href="https://www.tbcbank.ge/" target="_blank" rel="noreferrer">
            <img src="/tbc.svg" alt="partners" />
          </a>
          <a href="https://bankofgeorgia.ge/" target="_blank" rel="noreferrer">
            <img src="/bog.svg" alt="partners" />
          </a>
          <a href="https://libertybank.ge/" target="_blank" rel="noreferrer">
            <img src="/liberty.svg" alt="partners" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Main;
