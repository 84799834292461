import React, { useState, useEffect } from "react";
import style from "./Footer.module.css";
import axios from "axios";

function Footer() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const server = "back.grandmonetageorgia.com";

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const response = await axios.get(`https://${server}/branches/`);
        const firstBranch = response.data[0];
        setPhoneNumber(firstBranch.phone);
        setAddress(firstBranch.address);
      } catch (error) {
        console.error("Error fetching branch data:", error);
      }
    };

    fetchBranchData();
  }, []);

  return (
    <footer className={style.footer}>
      <img src="/gmg_logo.svg" alt="logo" />
      <div className={style.footer__developers}>
        <p>
          Design by{" "}
          <a
            href="https://taplink.cc/st_annie"
            target="_blank"
            rel="noreferrer"
          >
            Anna S
          </a>
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2"
          height="3"
          viewBox="0 0 2 3"
          fill="none"
        >
          <circle cx="1" cy="1.5" r="1" fill="white" />
        </svg>
        <p>
          Developed by{" "}
          <a href="https://kawaiier.dev/" target="_blank" rel="noreferrer">
            Kawaiier
          </a>
        </p>
      </div>
      <div className={style.footer__contacts}>
        <div className={style.footer__contacts__physical}>
          <ul>
            <li>
              <img src="/phone.svg" alt="phone icon" />
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </li>
            <li>
              <img src="/address.svg" alt="address icon" />
              <a
                href={`https://www.google.com/maps/place/${address}`}
                target="_blank"
                rel="noreferrer"
              >
                {address}
              </a>
            </li>
          </ul>
        </div>
        <div className={style.footer__contacts__social}>
          <a
            href="https://t.me/GrandCapitalGeorgia"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/telegram.svg" alt="telegram icon" />
          </a>
          <a
            href="mailto:grandmonetageorgia@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/mail.svg" alt="mail icon" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
