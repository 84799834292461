import { StrictMode, Suspense } from "react";
import styles from "./App.module.css";
import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";
import Footer from "./Components/Footer/Footer";
import "./i18n";

function App() {
  return (
    <StrictMode>

    <Suspense fallback="loading">
      <div className={styles.bg_container_header}>
        <div className={styles.bg_container_footer}>
          <Header />
          <main className={styles.main}>
            <Main />
          </main>
          <Footer />
        </div>
      </div>
    </Suspense>
    </StrictMode>
  );
}

export default App;
