import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Rates.module.css";
import { useTranslation } from "react-i18next";

function Rates() {
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false); // New state variable
  const { t } = useTranslation();
  const server = "back.grandmonetageorgia.com";

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get(`https://${server}/rates`);
        setRates(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching rates:", error);
        setLoading(false);
      }
    };

    fetchRates();
  }, []);

  return (
    <div className={styles.rates}>
      <h2>{t("exchange.title")}</h2>
      <p>{t("exchange.subtitle")}</p>
      {loading ? (
        <p>Loading rates...</p>
      ) : (
        <div className={styles.rates__container}>
          <table>
            <thead>
              <tr>
                <th width="60"></th>
                <th></th>
                <th width="86">{t("exchange.buy")}</th>
                <th width="86">{t("exchange.sell")}</th>
              </tr>
            </thead>
            <tbody>
              {rates
                .sort((a, b) => a.position - b.position)
                .slice(0, expanded ? rates.length : 3) // Modify slice() method
                .map((rate) => {
                  const rateName = rate.name.split("/")[1];
                  return (
                    <tr key={rate._id}>
                      <td>
                        <img
                          src={`/${rateName.toLowerCase()}.svg`}
                          alt={rate.name}
                        />
                      </td>
                      <td className={styles.rates__table_pair}>{rateName}</td>
                      <td className={styles.rates__table_price}>
                        {rate.rate.buy}
                      </td>
                      <td className={styles.rates__table_price}>
                        {rate.rate.sell}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <button onClick={() => setExpanded(!expanded)}>
            {expanded ? t("exchange.collapse") : t("exchange.expand")}{" "}
            {/* Button to toggle expansion state */}
          </button>
        </div>
      )}
    </div>
  );
}

export default Rates;
